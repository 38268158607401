.services-wrap {
    padding: 40px 0; // Further reduced padding for top and bottom

    .section-header {
        text-align: center;
        margin-bottom: 1.5rem; // Reduced spacing below the section header

        h2 {
            font-size: 1.75rem; // Smaller title
            margin-bottom: 0.5rem; // Spacing below the title
        }

        p {
            max-width: 400px; // Reduced maximum width for the paragraph
            margin: 0 auto; // Center the paragraph
            font-size: 0.875rem; // Smaller font size
            color: #6c757d; // Text color
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap; // Allow cards to wrap onto new lines

        .service-wrap {
            flex: 1 0 20%; // Further reduced width for smaller cards
            margin: 0.5rem; // Reduced spacing around each card
            min-width: 180px; // Adjusted minimum width for responsive behavior

            .card {
                display: flex;
                flex-direction: column;
                border: none; // Remove default border
                border-radius: 0.375rem; // Slightly rounded corners
                box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); // Reduced shadow
                height: 100%; // Full height to align content

                .card-header {
                    border-color: transparent;
                    height: 120px; // Reduced height for card header

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        display: block; // Ensure image takes full width and height
                    }
                }

                .card-body {
                    flex: 1; // Grow to fill available space
                    padding: 0.75rem; // Further reduced padding
                    text-align: center;

                    .card-title {
                        font-size: 1rem; // Smaller font size
                        margin-bottom: 0.5rem; // Reduced spacing below the title
                    }

                    .card-text {
                        font-size: 0.75rem; // Smaller font size
                        color: #495057;
                    }
                }

                .card-footer {
                    background: transparent;
                    border-top: none;
                    padding: 0.5rem;
                    text-align: center;

                    a.text-dark {
                        line-height: 1.25; // Adjust to ensure spacing
                        color: #007bff;

                        &:hover {
                            color: #0056b3;
                        }
                    }
                }
            }
        }
    }
}
